import * as React from 'react'

type Props= {}

export type State = {
	brandingData?: {
		paddingTop: number,
		color: string,
		top: string,
		second?: string | null,
		topHeight: number,
		backgroundSize?: string,
	} | null,
	transparentBackground?: boolean,
	setState?: (newState: State, cb: () => void) => void,
}

export const StateContext = React.createContext<State>({});

export class StateProvider extends React.Component<Props, State>
{
	state: State = {
		brandingData: null,
		transparentBackground: true,
		setState: (newState, cb) => {
			this.setState(newState, cb)
		}
	}

	render()
	{
		return (
			<StateContext.Provider
				value={this.state}
			>
				{this.props.children}
			</StateContext.Provider>
		)
	}
}

export const StateConsumer = StateContext.Consumer;